<template>
  <div class="m-header-pwa-widget" data-t="m-header-pwa-widget">
    <div class="widget-inner">
      <StIcon
        class="cross"
        name="cross-large"
        size="16"
        data-t="cross-icon"
        @click="closeWidget"
      />
      <img src="./logo.svg" alt="logo" class="img-betone" data-t="logo" />
      <div class="text-wrapper">
        <h3 class="title" data-t="title">{{ t('mHeaderWidget.title') }}</h3>
        <p class="description" data-t="description">
          {{ t('mHeaderWidget.description') }}
        </p>
      </div>
    </div>
    <StButton
      class="button"
      size="m"
      :label="t('mHeaderWidget.button')"
      data-t="install-button"
      @click="installPwa"
    />
  </div>
</template>

<script setup lang="ts">
import { usePwaWidgetVisibility } from '@st/pwa/composables/usePwaWidgetVisibility'
import { usePwaInstall } from '../../composables/usePwaInstall'

const { t } = useI18n()
const { installPwa } = usePwaInstall()
const { closeWidget } = usePwaWidgetVisibility()
</script>

<style scoped>
.m-header-pwa-widget {
  display: flex;
  gap: var(--spacing-200);
  align-self: stretch;
  justify-content: space-between;

  padding: var(--spacing-075) var(--spacing-200);

  background: var(--background-secondary);
}

.img-betone {
  width: 36px;
  height: 36px;
  margin-right: var(--spacing-100);
}

.widget-inner {
  display: flex;
  align-items: center;
  min-width: 0;
}

.title {
  margin-top: 0;
  margin-bottom: var(--spacing-050);
  font: var(--mobile-text-medium);
}

.description {
  margin: 0;
  font: var(--mobile-caption-1-regular);
  color: var(--text-secondary);
}

.cross {
  min-width: 16px;
  margin-right: var(--spacing-200);
  color: var(--icon-tertiary);
}

.button {
  margin-left: var(--spacing-200);
}

.text-wrapper {
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
}

.title,
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
