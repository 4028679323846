<template>
  <div class="m-sidebar" data-t="m-sidebar-vzf3">
    <div class="header">
      <button class="close" @click="closeMenu">
        <StIcon name="cross-large" size="24" />
      </button>
      <StInput
        :placeholder="t('menuMobile.search')"
        size="s"
        icon-prefix="search"
        @focus="openSearch"
      />
    </div>
    <div class="banners">
      <StMBanners :items="banners" :is-loading="isLoadingBanners" size="l" />
    </div>
    <div class="content-wrapper">
      <div class="tabs-wrapper" data-t="sidebar-tab-2m6r">
        <StTabs v-model="tab" :tabs="tabs" type="mobile-l" />
      </div>
      <div class="content">
        <Transition :name="transitionName">
          <div v-if="tab === 'casino'" class="slide">
            <MCasinoMenu />
          </div>
          <div v-else-if="tab === 'sport'" class="slide">
            <MSportMenu @select-sport="selectedSport = $event" />
          </div>
          <div v-else class="slide">
            <MCybersportMenu @select-sport="selectedSport = $event" />
          </div>
        </Transition>
      </div>
    </div>
    <Transition name="fade">
      <MNestedSportMenu
        v-if="selectedSport && tab !== 'casino'"
        :tab="tab"
        :sport="selectedSport"
        @back="selectedSport = undefined"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { Tab } from '@st/ui/components/StTabs/types'
import MCasinoMenu from '@st/casino/components/CasinoMenu/MCasinoMenu.vue'
import MSportMenu from '@st/bookmaker/components/SportMenu/MSportMenu.vue'
import MNestedSportMenu from '@st/bookmaker/components/SportMenu/MNestedSportMenu.vue'
import MCybersportMenu from '@st/bookmaker/components/CybersportMenu/MCybersportMenu.vue'
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'

const { closeMenu } = useMobileLayout()

const { t } = useI18n()
const tabs: Tab[] = [
  {
    id: 'casino',
    label: t('menuMobile.casino'),
  },
  {
    id: 'sport',
    label: t('menuMobile.sport'),
  },
  {
    id: 'cybersport',
    label: t('menuMobile.cybersport'),
  },
]

const tab = ref<'casino' | 'sport' | 'cybersport'>('casino')

const transitionName = ref<'slide-forward' | 'slide-backward'>('slide-forward')

watch(tab, (newValue, previousValue) => {
  const newIndex = tabs.findIndex(({ id }) => id === newValue)
  const oldIndex = tabs.findIndex(({ id }) => id === previousValue)

  transitionName.value =
    newIndex > oldIndex ? 'slide-forward' : 'slide-backward'
})

const selectedSport = ref<ExtendedMenuItem>()

const route = useRoute()
watch(() => route.fullPath, closeMenu)

const router = useRouter()
function openSearch() {
  closeMenu()
  router.replace({ query: { modal: 'search' } })
}

const { banners, isLoading: isLoadingBanners } = useBanners('mobileMenu')

watchEffect(() => {
  const path = router.currentRoute.value.fullPath
  if (path.includes('casino')) {
    tab.value = 'casino'
    return
  }
  if (path.includes('cybersport')) {
    tab.value = 'cybersport'
    return
  }
  if (path.includes('sport')) {
    tab.value = 'sport'
  }
})
</script>

<style scoped>
.m-sidebar {
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;

  background-color: var(--background-base);
}

.header {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  padding: var(--spacing-050);
  padding-right: var(--spacing-100);
}

.close {
  all: unset;
  padding: var(--spacing-100);
  line-height: 0;
}

.banners {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: var(--spacing-150);
  padding-bottom: var(--spacing-200);

  .banners-wrapper {
    overflow: scroll;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.tabs-wrapper {
  flex-shrink: 0;
  padding: var(--spacing-100);
  border-bottom: var(--border-width-light) solid var(--border-primary);
}

.content {
  overflow: auto;
  flex: 1 1 0;

  min-height: 0;
  margin-right: calc(var(--spacing-400) * -1);
  padding-right: var(--spacing-400);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  transform: translateX(0);
  width: 100vw;
}

.slide-backward-leave-to,
.slide-forward-enter-from {
  transform: translateX(100%);
}

.slide-backward-enter-from,
.slide-forward-leave-to {
  transform: translateX(-100%);
}

.slide-backward-leave-active,
.slide-backward-enter-active,
.slide-forward-leave-active,
.slide-forward-enter-active {
  position: absolute;
  transition: transform 0.4s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
