import {
  useLocalStorage,
  useApplicationType,
  useIsOnMounted,
} from '@st/use/composables'

export function usePwaWidgetVisibility() {
  const isWidgetOpen = useLocalStorage('pwaWidgetOpen', true)
  const { isPwa } = useApplicationType()
  const { isMounted } = useIsOnMounted()

  const isVisiblePwaWidget = computed(
    () => isMounted.value && isWidgetOpen.value && !isPwa.value,
  )

  function closeWidget() {
    isWidgetOpen.value = false
  }

  return {
    isVisiblePwaWidget,
    closeWidget,
  }
}
