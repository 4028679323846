<template>
  <footer class="footer" data-t="m-footer">
    <div v-if="!withoutFooterMenu" class="top" data-t="m-footer-top">
      <div class="social-media">
        <NuxtI18nLink
          v-for="(link, name) in socialMedia"
          :key="name"
          :to="link.path"
          class="social-link"
          target="_blank"
        >
          <StIcon :name="link.icon" :size="24" />
        </NuxtI18nLink>
      </div>
      <LanguageSelector type="primary" />
    </div>
    <nav v-if="!withoutFooterMenu" class="footer-menu">
      <StCollapse
        v-for="({ title, children, isExpanded }, i) in footerItems"
        :key="title"
        class="footer-collapse"
        data-t="m-footer-item"
        :model-value="isExpanded"
        @update:model-value="onCollapseClick(i)"
      >
        <template #activator="{ onClick, active }">
          <header class="name" @click="onClick">
            <h5>{{ title }}</h5>
            <StIcon
              :name="active ? 'chevron-top' : 'chevron-down'"
              :size="16"
              class="chevron"
            />
          </header>
        </template>
        <div class="footer-links">
          <NuxtI18nLink
            v-for="{ title: name, linkMobile, isNewWindow, icon } in children"
            :key="name"
            class="link"
            :to="linkMobile"
            :target="isNewWindow ? '_blank' : ''"
          >
            <div class="link-wrapper">
              <StIcon v-if="icon" :name="icon" size="16" />
              <span>{{ name }}</span>
            </div>
          </NuxtI18nLink>
        </div>
      </StCollapse>
    </nav>
    <div class="social" data-t="m-footer-social">
      <div class="logos">
        <NuxtI18nLink to="/" class="logo" target="_blank">
          <img src="./images/logo-mini.svg" alt="logotype" />
        </NuxtI18nLink>
        <LicenseValidator v-if="isShownLicenceValidator" size="s" />
        <span class="age">18+</span>
      </div>
      <p v-if="copyright" class="info">
        {{ copyright }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useFooter } from './useFooter'
import LanguageSelector from './parts/LanguageSelector.vue'
import LicenseValidator from './parts/LicenseValidator.vue'

defineProps<{
  withoutFooterMenu?: boolean
}>()

const {
  socialMedia,
  footerItems: footerItemsRaw,
  copyright,
  isShownLicenceValidator,
} = useFooter()

const footerItems = ref(
  footerItemsRaw.value.map((item) => ({
    ...item,
    isExpanded: false,
  })),
)

function onCollapseClick(i: number) {
  footerItems.value = footerItems.value.map((item, index) => ({
    ...item,
    isExpanded: index === i ? !item.isExpanded : false,
  }))
}
</script>

<style scoped>
.footer {
  margin-top: var(--spacing-100);
  padding-bottom: var(--spacing-200);
  border-top: 0.5px solid var(--border-primary);

  .footer-collapse {
    background-color: transparent;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .chevron {
      opacity: 0.48;
    }

    &.active {
      background-color: var(--background-primary);

      .chevron {
        opacity: 1;
      }
    }

    :deep(.content) {
      padding: 0;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-200);
  padding-bottom: var(--spacing-150);
}

.social-media {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-050);

  color: var(--text-primary);
}

.footer-menu {
  padding: 0 var(--spacing-050);
}

.footer-links {
  display: flex;
  flex-direction: column;

  padding-bottom: var(--spacing-100);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.link {
  display: flex;
  align-items: center;
  align-self: stretch;

  padding: var(--spacing-100) var(--spacing-150);

  font: var(--mobile-text-regular);
  color: var(--text-tertiary);
  text-decoration: none;

  border-radius: var(--border-radius-100);
}

.link-wrapper {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.name {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-125) var(--spacing-150);

  h5 {
    margin: 0;
    font: var(--mobile-headline-semibold);
    color: var(--text-primary);
  }
}

.social {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;

  padding: var(--spacing-500) var(--spacing-200) var(--spacing-600)
    var(--spacing-200);
}

.logos {
  display: flex;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: center;

  .logo img {
    width: 40px;
    height: 40px;
  }

  .curacao-logo img {
    width: 122px;
  }
}

.age {
  font: var(--desktop-text-lg-semibold);
  color: var(--text-quaternary);
  text-align: center;
}

.info {
  font: var(--mobile-caption-1-regular);
  color: var(--text-tertiary);
  text-align: center;
}
</style>
